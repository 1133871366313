import React from "react";
import { Link } from "gatsby";
import MetaTags from "../components/MetaTags";
import Container from "../components/Container";

const NotFoundPage = () => (
  <Container>
    <MetaTags title="404: Page not found<" />
    <h1>404: Page not found</h1>
    <p>
      I don't know what you were looking for, but whatever it was it's not here.
      Now <Link to="/">get outta here</Link> before this gets anymore awkward.
    </p>
  </Container>
);

export default NotFoundPage;
