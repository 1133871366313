import React from "react";
import styled from "styled-components";

const ContainerWrapper = styled.div`
  max-width: 800px;
  padding: 2rem 1rem;
  margin: 0 auto;

  @media (min-width: 700px) {
    padding: 2rem 3rem;
  }
`;

const Header = ({ children }) => (
  <ContainerWrapper>{children}</ContainerWrapper>
);

export default Header;
